import React, { useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ProjectHeader from "components/project-header";
import styles from "./create-update-custom-reports.module.css";
import Container from "components/container";
import ButtonComponent from "components/button-component";
import {
  Table,
  Tooltip,
  DatePicker,
  Button,
  Tabs,
  Input,
  Modal,
  ConfigProvider,
  Switch,
  Alert,
} from "antd";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import { ReportServices } from "apis/Services/ReportService";
import ExportReports from "components/export-reports";
import dayjs from "dayjs";

import {
  CopyOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CreateCustomColumn from "./create-custom-column";

const SortableColumnTitle = ({ children, id }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "300px",
    // height: "100%",
    opacity: isDragging ? 0.8 : 1,
    backgroundColor: isDragging ? "#f0f0f0" : "transparent",
    border: isDragging ? "1px dashed #ccc" : "none",
    whiteSpace: "wrap",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <span
        style={{
          // overflow: "hidden",
          // textOverflow: "ellipsis",
          whiteSpace: "wrap",
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default function CreateUpdateCustomReports() {
  const { company_id, id, reportName } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [accessLevel, setAccessLevel] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [unusedColumnsInViews, setUnusedColumnsInViews] = useState([]);

  const [searchParams] = useSearchParams();

  // const newTabIndex = useRef(0);
  // const navigate = useNavigate();

  let reportPageTitle = reportName;
  const reportOwner = searchParams.get("owner");
  let reportTabName = reportName ? searchParams.get("tab") : null;

  const [data, setData] = useState([]);

  const [reportDescription, setReportDescription] = useState("");
  const [alignedData, setAlignedData] = useState({});
  const [editedColumn, setEditedColumn] = useState();
  const [editedColumnKey, setEditedColumnKey] = useState();
  const [reportTabs, setReportTabs] = useState([]);
  const [newReportTabs, setNewReportTabs] = useState([]);
  const [deletedTabs, setdeletedTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(reportTabName);
  const [searchParam, setSearchParam] = useSearchParams();
  const [filteredInfo, setFilteredInfo] = useState({});
  const [columnInfoData, setColumnInfoData] = useState([]);
  const [newReportName, setNewReportName] = useState("");
  const [newReportDescription, setNewReportDescription] = useState("");

  const [tabsData, setTabsData] = useState({});
  const [unsavedTabs, setUnsavedTabs] = useState({});
  const [columns, setColumns] = useState([]);

  // FOR CUSTOM COLUMN
  const [showNote, setShowNote] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState(null);
  const [formulaRows, setFormulaRows] = useState([
    { columnKey: "", operator: "+", type: null },
  ]);

  const presetAlignedData = {
    specTitle: {
      label: "Submittal Title",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "1",
    },
    specNumber: {
      label: "Submittal Number",
      type: "string",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "2",
    },
    specRevision: {
      label: "Submittal Revision",
      type: "int",
      sorting: "",
      filtering: "",
      grouping: "",
      usedStatus: "used",
      formula: "",
      index: "3",
    },
  };

  function getData() {
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      project: id,
      code,
      loginEmail: email,
      reportName: reportName ? reportName : "",
      reportTabName: reportTabName ? reportTabName : "",
    };
    ReportServices.getDataToCreateUpdateCustomReport(ReqObj)
      .then((res) => {
        const combinedAlignedData =
          Object.keys(res?.reportsAlignedData).length > 0
            ? { ...res?.reportsAlignedData }
            : { ...presetAlignedData };

        Object.entries(res?.fullReportsAlignedData || {}).forEach(
          ([key, value]) => {
            if (!combinedAlignedData[key]) {
              combinedAlignedData[key] = {
                ...value,
                index: (Object.keys(combinedAlignedData).length + 1).toString(),
                usedStatus: "",
              };
            }
          }
        );

        setTabsData((prev) => ({
          ...prev,
          [reportTabName]: {
            reportInfo: {
              reportDescription: res?.reportInfo?.reportDescription,
              reportTabs: res?.reportInfo?.reportTabs,
            },
            oldTabName: reportTabName,
            newTabName: reportTabName,
            procurementData: res?.procurementLogEntriesCleaned,
            alignedData: combinedAlignedData,
            accessLevel: parseInt(res?.userData?.accessLevel),
          },
        }));

        if (reportTabName) {
          if (Object.keys(res?.reportsAlignedData).length < 1) {
            setUnsavedTabs((prev) => ({
              ...prev,
              [reportTabName]: true,
            }));
          }
        }

        setReportDescription(res?.reportInfo?.reportDescription);
        setReportTabs(res?.reportInfo?.reportTabs);
        setData(res?.procurementLogEntriesCleaned);
        setAccessLevel(parseInt(res?.userData?.accessLevel));
        setUnusedColumnsInViews(res?.unusedColumnsInViews);
        setAlignedData(combinedAlignedData);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setisLoading(false);
        setEditedData([]);
      });
  }
  useEffect(() => {
    const updatedFilteredInfo = Object.entries(alignedData).reduce(
      (acc, [key, value]) => {
        if (value.filtering) {
          acc[key] = value.filtering.split(",").map((item) => item.trim());
        }
        return acc;
      },
      {}
    );

    setFilteredInfo(updatedFilteredInfo);

    const data = Object.entries(alignedData || {})
      .filter(([key, value]) => value.label)
      .sort(([, a], [, b]) => (a.label || "").localeCompare(b.label || ""))
      .filter(
        ([key]) =>
          !unusedColumnsInViews.includes(key) &&
          key !== "entryID" &&
          key !== "group" &&
          key !== "specSection"
      );

    setColumnInfoData(data);
  }, [alignedData]);

  useEffect(() => {
    getData();
  }, []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    let newColumns = [];

    if (alignedData?.group) {
      newColumns.push({
        title: "Group",
        dataIndex: "group",
        key: "group",
        align: "left",
        width: 200,
        fixed: "left",
      });
    } else {
      newColumns.push(
        {
          title: "Submittal Title",
          dataIndex: "specTitle",
          key: "specTitle",
          align: "left",
          width: 400,
          // fixed: "left",
          defaultSortOrder:
            alignedData?.specTitle?.sorting === "Descending"
              ? "descend"
              : alignedData?.specTitle?.sorting === "Ascending"
              ? "ascend"
              : "",
          sorter: (a, b) => a.specTitle?.localeCompare(b.specTitle),
        },
        {
          title: "Submittal Number",
          dataIndex: "specNumber",
          key: "specNumber",
          align: "center",
          width: 150,
          // fixed: "left",
          defaultSortOrder:
            alignedData?.specNumber?.sorting === "Descending"
              ? "descend"
              : alignedData?.specNumber?.sorting === "Ascending"
              ? "ascend"
              : "",
          sorter: (a, b) => a.specNumber?.localeCompare(b.specNumber),
        },
        {
          title: "Submittal Revision",
          dataIndex: "specRevision",
          key: "specRevision",
          align: "center",
          width: 150,
          // fixed: "left",
          defaultSortOrder:
            alignedData?.specRevision?.sorting === "Descending"
              ? "descend"
              : alignedData?.specRevision?.sorting === "Ascending"
              ? "ascend"
              : "",
          sorter: (a, b) => Number(a.specRevision) - Number(b.specRevision),
        }
      );
    }

    newColumns = [
      ...newColumns,
      ...Object.entries(alignedData)
        .filter(
          ([key, value]) =>
            ![
              "specTitle",
              "specNumber",
              "specRevision",
              "specSection",
              "group",
            ].includes(key) && value.usedStatus === "used"
        )
        .sort((a, b) => a[1].index - b[1].index)
        .map(([key, value]) => {
          const uniqueValues = [
            ...new Set(dataSource.map((item) => item[key])),
          ];
          const columnConfig = {
            title: value.label || key,
            dataIndex: key,
            key: key,
            align: "center",
            filterMode: "tree",
            filterSearch: true,
            filteredValue: filteredInfo[key] || null,
            width: 200,
          };

          if (value.type === "int") {
            columnConfig.filters = uniqueValues
              .filter((v) => v !== undefined && v !== null)
              .map((uniqueValue) => ({
                text: uniqueValue !== "" ? uniqueValue : "[Blank]",
                value: uniqueValue !== "" ? uniqueValue : "",
              }));

            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];
              if (filterValue === "") {
                return cellValue === "";
              }
              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const numA = Number(a[key]);
              const numB = Number(b[key]);
              return numA - numB;
            };
            columnConfig.defaultSortOrder =
              value.sorting === "Descending"
                ? "descend"
                : value.sorting === "Ascending"
                ? "ascend"
                : undefined;
          } else if (value.type === "string") {
            columnConfig.filters = uniqueValues
              .filter((v) => v !== undefined && v !== null)
              .map((uniqueValue) => ({
                text: uniqueValue !== "" ? uniqueValue : "[Blank]",
                value: uniqueValue !== "" ? uniqueValue : "",
              }));

            columnConfig.onFilter = (filterValue, record) => {
              const cellValue = record[key];
              if (filterValue === "") {
                return cellValue === "";
              }
              return String(cellValue).startsWith(filterValue);
            };

            columnConfig.sorter = (a, b) => {
              const strA = String(a[key]);
              const strB = String(b[key]);
              return strA.localeCompare(strB);
            };
            columnConfig.defaultSortOrder =
              value.sorting === "Descending"
                ? "descend"
                : value.sorting === "Ascending"
                ? "ascend"
                : undefined;
          } else if (value.type === "date") {
            columnConfig.filterDropdown = ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              const handleConfirm = () => {
                if (startDate && endDate) {
                  // Format dates as MM-DD-YYYY
                  const formattedStartDate = startDate.format("MM-DD-YYYY");
                  const formattedEndDate = endDate.format("MM-DD-YYYY");

                  const filteringValue = `${formattedStartDate},${formattedEndDate}`;
                  const currentTabKey = selectedTab || reportTabName;

                  setTabsData((prev) => {
                    const updatedTabsData = { ...prev };

                    updatedTabsData[currentTabKey] = {
                      ...updatedTabsData[currentTabKey],
                      alignedData: {
                        ...updatedTabsData[currentTabKey]?.alignedData,
                        [key]: {
                          ...updatedTabsData[currentTabKey]?.alignedData[key],
                          filtering: filteringValue,
                        },
                      },
                    };

                    return updatedTabsData;
                  });

                  // Set the selected keys with the formatted dates
                  setSelectedKeys([formattedStartDate, formattedEndDate]);

                  setFilteredInfo((prev) => ({
                    ...prev,
                    [key]: {
                      startDate: startDate,
                      endDate: endDate,
                    },
                  }));

                  setUnsavedTabs((prev) => ({
                    ...prev,
                    [selectedTab]: true,
                  }));

                  confirm();
                }
              };

              const handleReset = () => {
                const currentTabKey = selectedTab || reportTabName;

                setTabsData((prev) => {
                  const updatedTabsData = { ...prev };

                  updatedTabsData[currentTabKey] = {
                    ...updatedTabsData[currentTabKey],
                    alignedData: {
                      ...updatedTabsData[currentTabKey]?.alignedData,
                      [key]: {
                        ...updatedTabsData[currentTabKey]?.alignedData[key],
                        filtering: "",
                      },
                    },
                  };

                  return updatedTabsData;
                });
                setStartDate(null);
                setEndDate(null);
                setSelectedKeys([]);
                clearFilters();
                confirm();
              };

              return (
                <div style={{ padding: 8 }}>
                  <div>
                    <strong style={{ width: "50%", marginBottom: 8 }}>
                      Pick a range
                    </strong>
                  </div>
                  <div className="d-flex gap-2 justify-content-between">
                    <DatePicker
                      // value={startDate}
                      onChange={(date) => setStartDate(date)}
                      format="MM-DD-YYYY"
                      style={{ width: "50%", marginBottom: 8 }}
                      placeholder="Start Date"
                    />
                    <DatePicker
                      // value={endDate}
                      onChange={(date) => setEndDate(date)}
                      format="MM-DD-YYYY"
                      style={{ width: "50%", marginBottom: 8 }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="d-flex justify-content-between flex-row-reverse">
                    <Button
                      type="primary"
                      onClick={handleConfirm}
                      size="small"
                      disabled={!startDate || !endDate}
                    >
                      OK
                    </Button>
                    <Button
                      onClick={handleReset}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              );
            };
            columnConfig.onFilter = (value, record) => {
              const recordDate = moment(record[key], "MM-DD-YYYY");

              if (!recordDate.isValid()) {
                // console.error("Invalid record date:", record[key]);
                return false;
              }

              let startDate = filteredInfo[key] ? filteredInfo[key][0] : null;
              let endDate = filteredInfo[key] ? filteredInfo[key][1] : null;

              // Convert startDate and endDate to moment objects
              const formattedStartDate = startDate
                ? moment(startDate, "MM-DD-YYYY")
                : null;
              const formattedEndDate = endDate
                ? moment(endDate, "MM-DD-YYYY")
                : null;

              // Check if the formattedStartDate and formattedEndDate are valid
              if (formattedStartDate && !formattedStartDate.isValid()) {
                console.error("Invalid start date:", startDate);
                return false;
              }

              if (formattedEndDate && !formattedEndDate.isValid()) {
                console.error("Invalid end date:", endDate);
                return false;
              }

              const isDateInRange = recordDate.isBetween(
                formattedStartDate,
                formattedEndDate,
                null,
                "[]"
              );

              // Return true if the date is within the range, false otherwise
              return isDateInRange;
            };

            columnConfig.sorter = (a, b) => {
              // Handle cases where the date might be null or undefined
              const dateA = a[key] ? dayjs(a[key]) : null;
              const dateB = b[key] ? dayjs(b[key]) : null;

              if (dateA && dateB) {
                return dateA.diff(dateB);
              }

              if (!dateA) return 1;
              if (!dateB) return -1;

              return 0;
            };
            columnConfig.defaultSortOrder =
              value.sorting === "Descending"
                ? "descend"
                : value.sorting === "Ascending"
                ? "ascend"
                : undefined;
            columnConfig.render = (text) =>
              text ? dayjs(text).format("MM-DD-YYYY") : "";
          }

          return columnConfig;
        }),
    ];

    setColumns(newColumns);
  }, [alignedData, filteredInfo, dataSource]);

  useEffect(() => {
    if (!isLoading) {
      const updatedDataSource = data.map((item, index) => ({
        key: item?.entryID || `entryID-${index}`,
        specTitle: `${item?.specTitle}`,
        specNumber: `${item?.specNumber}`,
        specRevision: `${item?.specRevision}`,
        ...Object.keys(alignedData).reduce((acc, column) => {
          acc[column] = item[column];
          return acc;
        }, {}),
      }));
      setDataSource(updatedDataSource);
    }
  }, [data, isLoading, alignedData]);

  const onTabChange = (key) => {
    // Update search params and selected tab
    setSearchParam((prevParams) => {
      prevParams.set("tab", key);
      return prevParams;
    });
    setSelectedTab(key);
    reportTabName = key;

    // Check if tab data is already loaded
    if (!tabsData[key]) {
      getData(key);
    } else {
      // Use locally stored data
      const tabData = tabsData[key];
      setReportDescription(tabData.reportInfo.reportDescription);
      setReportTabs(tabData.reportInfo.reportTabs);
      setData(tabData.procurementData);
      setAlignedData(tabData.alignedData);
    }
  };

  const saveReport = async () => {
    // console.log(tabsData, "tabsDataaa");

    const tabsToSave = Object.keys(unsavedTabs).filter(
      (tab) => !deletedTabs.includes(tab)
    );

    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    // console.log(tabsToSave, "Tabs to save:");

    // Save tabs
    const savePromises = tabsToSave.map((tabName) => {
      const tabData = tabsData[tabName];

      // Determine whether to create or update based on newReportTabs
      const isCreateMode = newReportTabs.includes(tabName) || !reportName;

      const saveRequest = isCreateMode
        ? {
            company: company_id,
            project: id,
            code,
            loginEmail: email,
            reportName: reportName || newReportName,
            reportTabName: tabName,
            reportDescription: newReportDescription || reportDescription || "",
            columnsInfo: tabData?.alignedData,
          }
        : {
            company: company_id,
            project: id,
            code,
            loginEmail: email,
            reportName: reportName,
            newReportName: newReportName || reportName,
            reportTabName: tabData?.oldTabName || tabName,
            newReportTabName: tabData?.newTabName || tabName,
            reportDescription: newReportDescription || reportDescription,
            columnsInfo: tabData?.alignedData,
          };

      console.log(
        `Sending ${
          isCreateMode ? "create" : "update"
        } request for tab: ${tabName}`,
        saveRequest
      );

      return isCreateMode
        ? ReportServices.createCustomReport(saveRequest)
        : ReportServices.updateCustomReport(saveRequest);
    });

    // Delete tabs
    let deletePromise = Promise.resolve();
    if (deletedTabs.length > 0) {
      const deleteRequest = {
        company: company_id,
        project: id,
        code,
        loginEmail: email,
        reportName: reportName,
        reportTabNames: deletedTabs,
      };

      // console.log("Sending delete request:", deleteRequest);

      deletePromise = ReportServices.deleteCustomReport(deleteRequest);
    }

    try {
      // Wait for all save and delete requests to complete
      await Promise.all([...savePromises, deletePromise]);

      const basePath = window.location.pathname
        .split("/")
        .slice(0, reportTabs ? -2 : -1)
        .join("/");

      window.location.href = `${basePath}/`;
    } catch (error) {
      console.error("Failed to save or delete tabs:", error);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    const currentTabKey = selectedTab || reportTabName;

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      updatedTabsData[currentTabKey] = {
        ...updatedTabsData[currentTabKey],
        alignedData: {
          ...updatedTabsData[currentTabKey].alignedData,
          ...Object.fromEntries(
            Object.entries(filters).map(([key, value]) => [
              key,
              {
                ...updatedTabsData[currentTabKey].alignedData[key],
                filtering: value ? value.join(",") : "",
              },
            ])
          ),
          ...(sorter && sorter.column
            ? {
                [sorter.column.dataIndex]: {
                  ...updatedTabsData[currentTabKey].alignedData[
                    sorter.column.dataIndex
                  ],
                  sorting:
                    sorter.order === "ascend"
                      ? "Ascending"
                      : sorter.order === "descend"
                      ? "Descending"
                      : "",
                },
              }
            : {}),
        },
      };

      return updatedTabsData;
    });

    setFilteredInfo(filters);

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));
  };

  // Search item names or submittal number
  const [searchQuery, setSearchQuery] = useState("");

  const isFilteredInfoEmpty = (filteredInfo) => {
    if (!filteredInfo || typeof filteredInfo !== "object") return true;
    const values = Object.values(filteredInfo);
    return values.length === 0 || values.every((value) => value == null);
  };

  const [isNewTabModalVisible, setIsNewTabModalVisible] = useState(false);
  const [isCustomColumnModalVisible, setIsCustomColumnModalVisible] =
    useState(false);
  const [newTabName, setNewTabName] = useState("");
  const [renameFlag, setRenameFlag] = useState(false);
  const [newRenamedTabsArray, setNewRenamedTabsArray] = useState([]);

  const add = () => {
    setIsNewTabModalVisible(true);
  };

  const handleNewTabConfirm = () => {
    if (!renameFlag) {
      if (newTabName.trim()) {
        // const currentTabs = Array.isArray(reportTabs) ? reportTabs : [];

        // setSearchParam((prevParams) => {
        //   prevParams.set("tab", newTabName.trim());
        //   return prevParams;
        // });

        // setSelectedTab(newTabName.trim());
        setNewReportTabs([...newReportTabs, newTabName.trim()]);
        onTabChange(newTabName.trim());
      }
    } else {
      setTabsData((prevTabsData) => {
        const updatedTabsData = { ...prevTabsData };

        if (updatedTabsData[selectedTab]) {
          updatedTabsData[selectedTab] = {
            ...updatedTabsData[selectedTab],
            newTabName: newTabName.trim(),
          };
        }
        return updatedTabsData;
      });

      let renamedTabs = [...newRenamedTabsArray];
      renamedTabs.push(newTabName.trim());

      setNewRenamedTabsArray(renamedTabs);
      setRenameFlag(false);
      setUnsavedTabs((prev) => ({
        ...prev,
        [selectedTab]: true,
      }));
    }

    setIsNewTabModalVisible(false);
    setNewTabName("");
  };

  const remove = (tabName) => {
    let newDeletedTabs = [...deletedTabs];
    let updatedNewReportTabs = [...newReportTabs];
    let updatedReportTabs = [...reportTabs];

    if (reportTabs.includes(tabName)) {
      newDeletedTabs.push(tabName);
      updatedReportTabs = reportTabs.filter((tab) => tab !== tabName);
    } else {
      updatedNewReportTabs = newReportTabs.filter((tab) => tab !== tabName);
    }

    setdeletedTabs(newDeletedTabs);
    setReportTabs(updatedReportTabs);
    setNewReportTabs(updatedNewReportTabs);
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  const handleRenameTab = (tabName) => {
    setRenameFlag(true);
    setNewTabName("");
    setIsNewTabModalVisible(true);
  };

  const filteredcolumnsInfo = columnInfoData.filter(([, value]) =>
    value.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Prevent drag and drop for (specTitle, specNumber, specRevision)
    const fixedColumns = ["specTitle", "specNumber", "specRevision", "group"];
    if (fixedColumns.includes(active.id) || fixedColumns.includes(over.id)) {
      return;
    }

    if (active.id !== over.id) {
      const updatedColumns = (prevColumns) => {
        const oldIndex = prevColumns.findIndex((col) => col.key === active.id);
        const newIndex = prevColumns.findIndex((col) => col.key === over.id);

        return arrayMove(prevColumns, oldIndex, newIndex);
      };

      const newColumns = updatedColumns(columns);

      setColumns(newColumns);

      setTabsData((prev) => {
        const currentTabKey = selectedTab || reportTabName;
        const updatedTabsData = { ...prev };

        if (updatedTabsData[currentTabKey]) {
          const updatedAlignedData = {
            ...updatedTabsData[currentTabKey].alignedData,
          };

          // Reset indexes based on new column order
          newColumns.forEach((column, index) => {
            const columnKey = column.key;
            if (updatedAlignedData[columnKey]) {
              updatedAlignedData[columnKey] = {
                ...updatedAlignedData[columnKey],
                index: index.toString(),
              };
            }
          });

          updatedTabsData[currentTabKey] = {
            ...updatedTabsData[currentTabKey],
            alignedData: updatedAlignedData,
          };
        }

        return updatedTabsData;
      });

      // Mark the tab as having unsaved changes
      setUnsavedTabs((prev) => ({
        ...prev,
        [selectedTab || reportTabName]: true,
      }));
    }
  };

  const parseFormulaToRows = (formula) => {
    if (!formula) return [{ columnKey: "", operator: "+", type: null }];

    const rows = [];
    let buffer = "";
    let operator = null;

    for (const char of formula) {
      if (["+", "-"].includes(char)) {
        if (buffer.trim()) {
          const columnKey = buffer.trim();
          const columnData = alignedData[columnKey] || {};
          rows.push({
            columnKey,
            operator,
            type: columnData.type || null,
          });
        }
        operator = char;
        buffer = "";
      } else {
        buffer += char;
      }
    }

    if (buffer.trim()) {
      const columnKey = buffer.trim();
      const columnData = alignedData[columnKey] || {};
      rows.push({
        columnKey,
        operator,
        type: columnData.type || null,
      });
    }

    if (rows.length > 0 && rows[0].operator === null) {
      rows[0].operator = "+";
    }

    return rows;
  };

  const saveCustomColumn = (formula, columnName, columnType) => {
    const currentTabKey = selectedTab || reportTabName;

    // Determine which column key to use
    const columnKeyToUpdate = editedColumnKey || availableCustomColumns[0];

    if (!columnKeyToUpdate) {
      console.error("No available custom columns");
      return;
    }

    // Create the updated aligned data for the current tab
    const updatedAlignedData = {
      ...tabsData[currentTabKey].alignedData,
      [columnKeyToUpdate]: {
        ...tabsData[currentTabKey].alignedData[columnKeyToUpdate],
        usedStatus: "used",
        formula: formula.toString(),
        label: columnName,
        type: columnType.toString(),
      },
    };

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey] = {
          ...updatedTabsData[currentTabKey],
          alignedData: updatedAlignedData,
        };
      }

      return updatedTabsData;
    });

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));

    setIsCustomColumnModalVisible(false);

    setEditedColumn(null);
    setEditedColumnKey(null);
    setColumnName("");
    setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
    setColumnType(null);
  };

  const handleEditColumn = (key, column) => {
    setEditedColumn(column);
    setEditedColumnKey(key);
    setColumnName(column.label || "");
    const initialRows = parseFormulaToRows(column.formula);
    setFormulaRows(initialRows);
    setColumnType(initialRows[0]?.type || null);
    setIsCustomColumnModalVisible(true);
  };

  const handleDeleteColumn = (column) => {
    // console.log(column, editedColumnKey, "HAAAA???");

    const currentTabKey = selectedTab || reportTabName;

    // Create the updated aligned data for the current tab
    const updatedAlignedData = {
      ...tabsData[currentTabKey].alignedData,
      [editedColumnKey]: {
        ...tabsData[currentTabKey].alignedData[editedColumnKey],
        usedStatus: "",
        formula: "",
        label: "",
        type: "",
      },
    };

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };

      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey] = {
          ...updatedTabsData[currentTabKey],
          alignedData: updatedAlignedData,
        };
      }

      return updatedTabsData;
    });

    setUnsavedTabs((prev) => ({
      ...prev,
      [currentTabKey]: true,
    }));

    setIsCustomColumnModalVisible(false);

    setEditedColumn(null);
    setEditedColumnKey(null);
    setColumnName("");
    setFormulaRows([{ columnKey: "", operator: "+", type: null }]);
    setColumnType(null);
  };

  let availableCustomColumns = Object.entries(alignedData || {}).reduce(
    (acc, [key, value]) => {
      if (key.includes("reportsCustom") && value?.formula === "") {
        acc.push(key);
      }
      return acc;
    },
    []
  );
  const handleSwitchChange = (checked) => {
    const currentTabKey = selectedTab || reportTabName;

    // Update columns' usedStatus excluding specific fields
    const updatedAlignedData = Object.fromEntries(
      Object.entries(tabsData[currentTabKey]?.alignedData || {}).map(
        ([key, value]) => {
          if (
            key === "entryID" ||
            key === "specTitle" ||
            key === "specRevision" ||
            key === "specNumber" ||
            unusedColumnsInViews.includes(key) ||
            (key.includes("reportsCustom") && alignedData[key].label === "")
          ) {
            return [key, value];
          }

          return [
            key,
            {
              ...value,
              usedStatus: checked ? "used" : "",
            },
          ];
        }
      )
    );

    setAlignedData(updatedAlignedData);

    setTabsData((prev) => {
      const updatedTabsData = { ...prev };
      if (updatedTabsData[currentTabKey]) {
        updatedTabsData[currentTabKey].alignedData = updatedAlignedData;
      }
      return updatedTabsData;
    });

    // Mark the tab as having unsaved changes
    setUnsavedTabs((prevUnsaved) => ({
      ...prevUnsaved,
      [currentTabKey]: true,
    }));

    // console.log(updatedAlignedData, "??");
  };

  const shouldIncludeKey = (key) => {
    return (
      key !== "entryID" &&
      key !== "specTitle" &&
      key !== "specRevision" &&
      key !== "specNumber" &&
      !unusedColumnsInViews.includes(key) &&
      !(key.includes("reportsCustom") && alignedData[key].label === "")
    );
  };

  const allUsed = Object.keys(alignedData)
    .filter(shouldIncludeKey)
    .every((key) => alignedData[key].usedStatus === "used");

  const numberOfTabs = [
    ...(reportTabs || []),
    ...(newReportTabs || []),
  ]?.filter((item) => !deletedTabs.includes(item));

  // console.log(numberOfTabs, "!?!??!");

  return (
    <>
      <Container>
        <div className="row mt-4">
          {isLoading && !accessLevel ? (
            <>
              <LoadingComponent />
            </>
          ) : (
            <>
              {/* When Request Fails  */}
              {!accessLevel ? (
                <div className="row">
                  <div className="text-center">
                    <Text>Something went wrong, please try again later.</Text>
                  </div>
                </div>
              ) : accessLevel < 2 ? (
                <div className="row">
                  <div className="text-center">
                    <Text>
                      You do not have the necessary permissions to access this
                      page.
                    </Text>
                  </div>
                </div>
              ) : (
                // Main view
                <div className="d-flex" style={{ overflow: "hidden" }}>
                  <div
                    style={{ flex: 1, overflowY: "auto", paddingRight: "10px" }}
                  >
                    <>
                      <div className=" bg-white p-2  py-3 rounded-3 mb-2">
                        <div className=" d-flex justify-content-between ">
                          <div className=" d-flex flex-column gap-2">
                            <div>
                              <small>
                                Report Name{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </small>
                              <Input
                                maxLength={30}
                                showCount
                                defaultValue={
                                  reportPageTitle ? reportPageTitle : ""
                                }
                                onChange={(e) => {
                                  setNewReportName(e.target.value);
                                  if (selectedTab) {
                                    setUnsavedTabs((prev) => ({
                                      ...prev,
                                      [selectedTab]: true,
                                    }));
                                  }
                                }}
                                placeholder="Report Name"
                              />
                            </div>
                            <div>
                              <small>Report Description</small>
                              <Input
                                maxLength={100}
                                showCount
                                defaultValue={
                                  reportDescription ? reportDescription : ""
                                }
                                onChange={(e) => {
                                  setNewReportDescription(e.target.value);
                                  if (selectedTab) {
                                    setUnsavedTabs((prev) => ({
                                      ...prev,
                                      [selectedTab]: true,
                                    }));
                                  }
                                }}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                          <div className="d-flex gap-2">
                            {/* <Tooltip title="Share Report">
                              <Button
                                shape="circle"
                                icon={
                                  <ShareAltOutlined
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                }
                              />
                            </Tooltip> */}
                            {/* <Tooltip title="Clone Report">
                              <Button
                                shape="circle"
                                icon={
                                  <CopyOutlined
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  />
                                }
                              />
                            </Tooltip> */}
                            <Button
                              onClick={saveReport}
                              type="primary"
                              disabled={
                                // numberOfTabs.length === 0 ||
                                Object.keys(unsavedTabs).length === 0 &&
                                deletedTabs.length === 0
                              }
                            >
                              Save Report
                            </Button>
                          </div>
                        </div>
                        {/* <hr /> */}
                        <div className=" d-flex justify-content-end">
                          {/* GROUPING HERE */}

                          {/* <Button
                            onClick={saveReport}
                            type="primary"
                            disabled={
                              Object.keys(unsavedTabs).length === 0 &&
                              deletedTabs.length === 0 &&
                              !newReportName &&
                              !newReportDescription
                            }
                          >
                            Save Report
                          </Button> */}
                        </div>
                      </div>

                      <Tabs
                        type="editable-card"
                        onChange={onTabChange}
                        onEdit={onEdit}
                        tabBarExtraContent={
                          <Button
                            onClick={() => {
                              setFilteredInfo({});

                              // update tab data
                              setTabsData((prev) => {
                                const currentTabKey =
                                  selectedTab || reportTabName;
                                const updatedTabsData = { ...prev };

                                updatedTabsData[currentTabKey] = {
                                  ...updatedTabsData[currentTabKey],
                                  alignedData: Object.fromEntries(
                                    Object.entries(
                                      updatedTabsData[currentTabKey]
                                        ?.alignedData || {}
                                    ).map(([key, value]) => [
                                      key,
                                      {
                                        ...value,
                                        filtering: "",
                                      },
                                    ])
                                  ),
                                };

                                return updatedTabsData;
                              });

                              setUnsavedTabs((prev) => ({
                                ...prev,
                                [selectedTab]: true,
                              }));
                            }}
                            style={{
                              marginBottom: "20px",
                            }}
                            disabled={isFilteredInfoEmpty(filteredInfo)}
                          >
                            Clear Filters
                          </Button>
                        }
                        activeKey={selectedTab ? selectedTab : reportTabName}
                        className="rounded-3"
                        addIcon={
                          <div
                            style={{
                              width: "30px",
                              height: "42px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PlusOutlined />
                          </div>
                        }
                        tabBarStyle={{
                          marginBottom: "-1px",
                        }}
                        style={{
                          background: "white",
                          padding: "20px 10px 10px 10px ",
                        }}
                        items={[...(reportTabs || []), ...(newReportTabs || [])]
                          ?.filter((item) => !deletedTabs.includes(item))
                          ?.map((item, index) => ({
                            label: (
                              <div className="d-flex align-items-center gap-2">
                                {selectedTab === item && (
                                  <SettingFilled
                                    onClick={() => {
                                      if (!isLoading) {
                                        handleRenameTab(item);
                                      }
                                    }}
                                    style={{
                                      padding: "0px",
                                      marginRight: "0px",
                                      cursor: isLoading
                                        ? "not-allowed"
                                        : "pointer",
                                      color: "black",
                                    }}
                                  />
                                )}
                                <span>
                                  {tabsData[item]?.newTabName || item}
                                </span>
                              </div>
                            ),
                            key: item,
                            children: (
                              <div>
                                <DndContext
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={handleDragEnd}
                                >
                                  <SortableContext
                                    items={columns.map((col) => col.key)}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    <Table
                                      columns={columns.map((column, index) => ({
                                        ...column,
                                        title:
                                          column.key === "specTitle" ||
                                          column.key === "specNumber" ||
                                          column.key === "specRevision" ||
                                          column.key === "group" ? (
                                            column.title
                                          ) : (
                                            <SortableColumnTitle
                                              id={column.key}
                                            >
                                              {column.title}
                                            </SortableColumnTitle>
                                          ),
                                      }))}
                                      dataSource={dataSource}
                                      scroll={{ x: 500, y: "70vh" }}
                                      bordered
                                      loading={
                                        isLoading
                                          ? { indicator: <LoadingComponent /> }
                                          : false
                                      }
                                      pagination={{
                                        pageSize: "10",
                                      }}
                                      onChange={handleChange}
                                      size="small"
                                    />
                                  </SortableContext>
                                </DndContext>
                                <div className=" p-2 mt-1 d-flex justify-content-between align-items-center ">
                                  <i>Preview: Only displaying first 10 rows</i>
                                  {showNote && (
                                    <Alert
                                      type="warning"
                                      message="The calculation results will be visible once you save the report."
                                      closable
                                    ></Alert>
                                  )}
                                </div>
                              </div>
                            ),
                          }))}
                      />

                      <Modal
                        title={!renameFlag ? "Add New Tab" : "Rename Tab Name"}
                        open={isNewTabModalVisible}
                        onOk={handleNewTabConfirm}
                        onCancel={() => {
                          setIsNewTabModalVisible(false);
                          setRenameFlag(false);
                          setNewTabName("");
                        }}
                        okButtonProps={{
                          disabled:
                            !newTabName.trim() ||
                            [
                              ...(reportTabs || []),
                              ...(newReportTabs || []),
                              ...(deletedTabs || []),
                              ...(newRenamedTabsArray || []),
                            ].includes(newTabName.trim()),
                        }}
                      >
                        {[
                          ...(reportTabs || []),
                          ...(newReportTabs || []),
                          ...(deletedTabs || []),
                          ...(newRenamedTabsArray || []),
                        ].includes(newTabName.trim()) && (
                          <div className=" mb-1">
                            <Alert
                              message="Tab name must be unique. A tab with this name already exists."
                              type="error"
                              showIcon
                            />
                          </div>
                        )}
                        <Input
                          placeholder="Enter tab name"
                          value={newTabName}
                          onChange={(e) => setNewTabName(e.target.value)}
                          maxLength={30}
                          showCount
                          status={
                            [
                              ...(reportTabs || []),
                              ...(newReportTabs || []),
                              ...(deletedTabs || []),
                            ].includes(newTabName)
                              ? "error"
                              : ""
                          }
                        />
                      </Modal>

                      <Modal
                        title="Custom Column"
                        open={isCustomColumnModalVisible}
                        onCancel={() => {
                          setEditedColumn(null);
                          setEditedColumnKey(null);
                          setIsCustomColumnModalVisible(false);
                          setColumnName("");
                          setFormulaRows([
                            { columnKey: "", operator: "+", type: null },
                          ]); // Reset formula
                          setColumnType(null);
                        }}
                        footer={null}
                      >
                        <CreateCustomColumn
                          columnName={columnName}
                          columnType={columnType}
                          setColumnType={setColumnType}
                          setColumnName={setColumnName}
                          formulaRows={formulaRows}
                          setFormulaRows={setFormulaRows}
                          column={editedColumn}
                          alignedData={alignedData}
                          unusedColumnsInViews={unusedColumnsInViews}
                          editedColumn={editedColumn}
                          parseFormulaToRows={parseFormulaToRows}
                          saveCustomColumn={saveCustomColumn}
                          handleDeleteColumn={handleDeleteColumn}
                          setShowNote={setShowNote}
                        />
                      </Modal>
                    </>
                  </div>
                  {/* Right-side scrollable div */}
                  <div
                    className="rounded-3"
                    style={{
                      width: "300px",
                      overflowY: "auto",
                      background: "#FAFAFA",
                      height: "90vh",
                      position: "relative",
                    }}
                  >
                    <div
                      className=" d-flex flex-column gap-3 w-100 pt-3 px-3 pb-3 border-bottom mb-2 bg-white "
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        background: "#FFFFFF",
                      }}
                    >
                      <div className=" w-100">
                        <Button
                          type="primary"
                          className="w-100"
                          onClick={() => {
                            setIsCustomColumnModalVisible(true);
                          }}
                          disabled={
                            availableCustomColumns.length < 1 ||
                            !selectedTab ||
                            numberOfTabs.length === 0 ||
                            isLoading
                          }
                        >
                          Create Custom Column
                        </Button>
                      </div>
                      <Input
                        placeholder="Search Columns"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        disabled={isLoading}
                        suffix={<SearchOutlined style={{ opacity: "50%" }} />}
                      />
                      <div className=" d-flex justify-content-between align-items-center">
                        <small className="fw-semibold">Toggle All</small>
                        <Switch
                          checked={allUsed}
                          size="small"
                          disabled={
                            !selectedTab ||
                            numberOfTabs.length === 0 ||
                            isLoading
                          }
                          onChange={handleSwitchChange}
                        />
                      </div>
                    </div>
                    <div className=" px-3">
                      {filteredcolumnsInfo && selectedTab ? (
                        filteredcolumnsInfo.map(([key, value], index) => (
                          <div
                            key={key}
                            className="d-flex justify-content-between align-items-center"
                            style={{
                              borderBottom: "1px solid #e8e8e8",
                              paddingBottom: "8px",
                              paddingTop: index !== 0 ? "8px" : "3px",
                            }}
                          >
                            <div className=" d-flex align-items-center gap-2">
                              {key.includes("reportsCustom") && (
                                <>
                                  <Tooltip
                                    title="Edit Column"
                                    style={{
                                      cursor: isLoading
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  >
                                    <SettingFilled
                                      onClick={() => {
                                        if (!isLoading) {
                                          handleEditColumn(key, value);
                                        }
                                      }}
                                      style={{
                                        fontSize: "14px",
                                        cursor: isLoading
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              )}
                              <small>{value.label}</small>
                            </div>
                            <Switch
                              checked={value.usedStatus === "used"}
                              disabled={
                                key === "specTitle" ||
                                key === "specRevision" ||
                                key === "specNumber" ||
                                !selectedTab ||
                                numberOfTabs.length === 0 ||
                                isLoading
                              }
                              size="small"
                              onChange={(checked) => {
                                const currentTabKey =
                                  selectedTab || reportTabName;

                                const updatedsAlignedData = {
                                  ...tabsData[currentTabKey]?.alignedData,
                                  [key]: {
                                    ...tabsData[currentTabKey]?.alignedData?.[
                                      key
                                    ],
                                    usedStatus: checked ? "used" : "",
                                  },
                                };
                                setAlignedData(updatedsAlignedData);

                                setTabsData((prev) => {
                                  const updatedTabsData = { ...prev };

                                  if (!updatedTabsData[currentTabKey]) {
                                    updatedTabsData[currentTabKey] = {};
                                  }
                                  updatedTabsData[currentTabKey].alignedData =
                                    updatedsAlignedData;

                                  setUnsavedTabs((prevUnsaved) => ({
                                    ...prevUnsaved,
                                    [currentTabKey]: true,
                                  }));

                                  return updatedTabsData;
                                });
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No data available.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
}
