import { Network } from "../Network";
import { ReportsEndPoints } from "apis/EndPoints/ReportsEndPoints";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class ReportServices {
  static readCustomReports(data = {}) {
    return Network.fetch(
      ReportsEndPoints.readAllCustomReportsInfo.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.readAllCustomReportsInfo.method,
      }
    );
  }
  static readSharedCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.readSharedCustomReport.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.readSharedCustomReport.method,
      }
    );
  }
  static readCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.readCustomReport.url(
        data?.company,
        data?.project,
        data?.reportName,
        data?.reportTabName,
        data?.reportOwner
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.readCustomReport.method,
        body: JSON.stringify(data),
      }
    );
  }

  static getDataToCreateUpdateCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.getDataToCreateUpdateCustomReport.url(
        data?.company,
        data?.project,
        data?.reportName,
        data?.reportTabName
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.getDataToCreateUpdateCustomReport.method,
        body: JSON.stringify(data),
      }
    );
  }

  static createCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.createCustomReport.url(
        data?.company,
        data?.project,
        data?.reportName,
        data?.reportTabName
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.createCustomReport.method,
        body: JSON.stringify(data),
      }
    );
  }

  static updateCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.updateCustomReport.url(
        data?.company,
        data?.project,
        data?.reportName,
        data?.reportTabName
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.updateCustomReport.method,
        body: JSON.stringify(data),
      }
    );
  }

  static deleteCustomReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.deleteCustomReport.url(
        data?.company,
        data?.project,
        data?.reportName,
        data?.reportTabName
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.deleteCustomReport.method,
        body: JSON.stringify(data),
      }
    );
  }
  static getReportDetails(data = {}) {
    return Network.fetch(
      ReportsEndPoints.getReportDetails.url(
        data?.company,
        data?.project,
        data?.firstColumnName,
        data?.secondColumnName,
        data?.minValue,
        data?.maxValue,
        data?.sortBy,
        data?.status,
        data?.filteredColumns,
        data?.filterCombination,
        data?.subcontractorFilter,
        data?.reportRequestName,
        data?.responseFilter
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.getReportDetails.method,
        body: JSON.stringify(data),
      }
    );
  }
  static getReportAccessLevel(data = {}) {
    return Network.fetch(
      ReportsEndPoints.getReportAccessLevel.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.getReportAccessLevel.method,
      }
    );
  }

  static modifyIssueDateReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.modifyIssueDateReport.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.modifyIssueDateReport.method,
        body: JSON.stringify(data?.editedData),
      }
    );
  }
}
